body {
  background: #f7fafc;
}

.xs_input .MuiInputLabel-root,
.xs_select .MuiInputLabel-root {
  font-size: 12px !important;
}
.xs_input .MuiOutlinedInput-input {
  padding: 7.5px 14px !important;
  font-size: 12px !important;
}
.xs_input p {
  font-size: 12px !important;
}
.xs_input svg {
  width: 18px !important;
  height: 18px !important;
  top: 8px !important;
}
.xs_select .MuiOutlinedInput-input {
  padding: 4.5px 14px !important;
  font-size: 12px !important;
}
.xs_select svg {
  width: 18px !important;
  height: 18px !important;
  top: 8px !important;
}

.linkStyle {
  text-decoration: none;
  color: none;
}

.xs_textfeild .MuiOutlinedInput-input {
  color: "#718096";
  padding: "7.5px 14px";
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}

.grecaptcha-badge { 
  visibility: hidden !important;
}
